import React from 'react';

import styles from './component.module.scss';

const Input = React.forwardRef<
  HTMLInputElement,
  React.JSX.IntrinsicElements['input'] & { error?: string }
>(({ error, ...props }, ref) => {
  return (
    <div className={styles.main}>
      <input {...props} className={styles.input} ref={ref} />
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
});

export { Input };
