import React from 'react';
import { ScaleLoader } from 'react-spinners';
import clsx from 'clsx';

import ButtonTexture from '@/assets/textures/button.png';

import styles from './component.module.scss';

const Button = React.forwardRef<
  HTMLButtonElement,
  {
    loading?: boolean;
  } & React.JSX.IntrinsicElements['button']
>(({ loading, children, ...props }, ref) => {
  return (
    <button
      {...props}
      onClick={props.disabled ? undefined : props.onClick}
      className={clsx(styles.main, props.disabled && styles.disabled)}
      ref={ref}
    >
      <div
        className={styles.inner}
        style={{ backgroundImage: `url(${ButtonTexture})` }}
      >
        {loading ? <ScaleLoader height={10} color="#a0a0a0" /> : children}
      </div>
    </button>
  );
});

export { Button };
