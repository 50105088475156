import React from 'react';
import { ScaleLoader } from 'react-spinners';
import clsx from 'clsx';

import ButtonTexture from '@/assets/textures/button.png';

import styles from './component.module.scss';

export const Link = React.forwardRef<
  HTMLAnchorElement,
  {
    loading?: boolean;
    disabled?: boolean;
  } & React.JSX.IntrinsicElements['a']
>(({ loading, children, ...props }, ref) => {
  return (
    <a
      {...props}
      className={clsx(
        styles.main,
        props.disabled && styles.disabled,
        props.className
      )}
      ref={ref}
    >
      <div
        className={styles.inner}
        style={{ backgroundImage: `url(${ButtonTexture})` }}
      >
        {loading ? <ScaleLoader height={10} color="#a0a0a0" /> : children}
      </div>
    </a>
  );
});
