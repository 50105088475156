import { CognitoIdentityProvider } from '@aws-sdk/client-cognito-identity-provider';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';

import { CognitoPoolUser } from './cognito_pool_user.ts';
import {
  cachedValueOrNull,
  deleteCachedValue,
  setCachedValue
} from './utils/index.ts';

type CookieAttributes = (typeof Cookies)['attributes'];

class CognitoPool {
  protected client: CognitoIdentityProvider;
  constructor(
    public readonly storeNamespace: string,
    public readonly region: string,
    public readonly userPoolId: string,
    public readonly userPoolClientId: string,
    public readonly cookieSettings?: { name: string } & CookieAttributes
  ) {
    this.client = new CognitoIdentityProvider({ region });
  }

  getUser(username: string) {
    return new CognitoPoolUser(this.client, this, username);
  }

  setStoreUser(user: CognitoPoolUser) {
    setCachedValue(
      `${this.storeNamespace}:${this.userPoolId}`,
      'local',
      user.serialize(),
      dayjs().add(3, 'hours').valueOf()
    );

    if (this.cookieSettings) {
      Cookies.set(this.cookieSettings.name, user.authFlow.accessToken, {
        ...this.cookieSettings,
        expires: user.authFlow.accessTokenExp.toDate()
      });
    }
  }

  getStoreUser() {
    const serializedUser = cachedValueOrNull<
      ReturnType<CognitoPoolUser['serialize']>
    >(`${this.storeNamespace}:${this.userPoolId}`, 'local');

    if (serializedUser === null) {
      return null;
    }

    const user = CognitoPoolUser.fromTokens(this.client, this, serializedUser);

    if (this.cookieSettings) {
      Cookies.set(this.cookieSettings.name, user.authFlow.accessToken, {
        ...this.cookieSettings,
        expires: user.authFlow.accessTokenExp.toDate()
      });
    }

    return user;
  }

  deleteStoreUser() {
    deleteCachedValue(`${this.storeNamespace}:${this.userPoolId}`, 'local');

    if (this.cookieSettings) {
      Cookies.remove(this.cookieSettings.name, { ...this.cookieSettings });
    }
  }
}

export { CognitoPool };
