import axios from 'axios';
import React from 'react';

import { ApiDeploymentDomains } from '@veriok/infra';
import { EnvironmentContext } from '@veriok/lib';

import { useAuthProvider } from '../AuthProvider/index.tsx';

import { IApiProviderContext } from './types.ts';

const ApiProviderContext = React.createContext<IApiProviderContext | null>(
  null
);

const ApiProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuthProvider();

  const api = axios.create({
    baseURL: `https://${ApiDeploymentDomains[EnvironmentContext.stage]}`
  });

  const apiAuthenticated = axios.create({
    baseURL: `https://${ApiDeploymentDomains[EnvironmentContext.stage]}`
  });
  apiAuthenticated.interceptors.request.use((config) => {
    if (!auth.cognitoUser) {
      throw new Error('Authenticated API is not available');
    }

    config.headers = config.headers ?? {};

    config.headers[
      'Authorization'
    ] = `Bearer ${auth.cognitoUser.authFlow.accessToken}`;

    return config;
  });

  const providerContext: IApiProviderContext = {
    api,
    apiAuthenticated
  };

  return (
    <ApiProviderContext.Provider value={providerContext}>
      {children}
    </ApiProviderContext.Provider>
  );
};

export { ApiProvider, ApiProviderContext };
