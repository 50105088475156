import { Navigate, RouteObject } from 'react-router-dom';

import { SignInView } from '@/features/auth/views/index.ts';
import { ServerDashboardView } from '@/features/server/views/index.ts';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to="/server" />,
    index: true
  },
  {
    path: '/auth/signin',
    element: <SignInView />
  },
  { path: '/server', element: <ServerDashboardView /> }
];

export { routes };
