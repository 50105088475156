import * as Yup from 'yup';
import * as jose from 'jose';
import dayjs from 'dayjs';

const passwordValidator = Yup.string()
  .test(
    'password-length',
    'Password must be at least 8 characters long',
    (value: any) => {
      return value && value.length >= 8;
    }
  )
  .test(
    'password-contents',
    'Password must contain an uppercase letter, number, and a special character',
    (value: any) => {
      if (!value) return false;

      return (
        value.match(/[A-Z]+/) &&
        value.match(/[a-z]+/) &&
        value.match(/[0-9]+/) &&
        value.match(/[@$!%*#?&]+/)
      );
    }
  );

const emailValidator = Yup.string().test(
  'email-syntax',
  'Invalid email address',
  (value: any) => {
    return !!(
      typeof value === 'string' &&
      value.match(
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
      )
    );
  }
);

const jwtTokenValidator = Yup.string().test(
  'jwt-parsable',
  'Invalid token',
  (value: any) => {
    try {
      const claims = jose.decodeJwt(value);
      return (
        (!claims.exp || dayjs().isBefore(dayjs.unix(claims.exp))) &&
        (!claims.nbf || dayjs().isAfter(dayjs.unix(claims.nbf)))
      );
    } catch (e) {
      return false;
    }
  }
);

const phoneNumberValidator = Yup.string().test(
  'phoneNumber-syntax',
  'Invalid phone number',
  (value: any) => {
    return !!(typeof value === 'string' && value.match(/^\+[1-9]\d{1,14}$/g));
  }
);

const notNullValidator = Yup.string().test(
  'empty-field',
  'Field cannot be empty',
  (value: any) => {
    if (!value) return false;

    return value.match(/[a-z]*[A-Z]*/);
  }
);

const yupSchemaToFormValidate = <T extends Yup.Schema>(schema: T) => {
  return async (value: any) => {
    try {
      await schema.validate(value);
      return true;
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        return e.message;
      }
      if (e instanceof Error) {
        return e.message;
      }
      return String(e);
    }
  };
};

export {
  passwordValidator,
  emailValidator,
  notNullValidator,
  phoneNumberValidator,
  jwtTokenValidator,
  yupSchemaToFormValidate
};
