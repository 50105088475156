// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CqrqOsr0e73kQjOUFQnz{white-space:pre-wrap}.HxAOgaXs9RXFUjbVwG1g{display:flex;width:100%;max-width:1080px;flex-flow:row wrap;justify-content:space-evenly;gap:30px}.HxAOgaXs9RXFUjbVwG1g .I16FnPGO_Sfaa7Wa_mfk{display:grid;width:100%;max-width:300px;grid-template-columns:1fr 2fr;grid-auto-rows:min-content;gap:15px}.HxAOgaXs9RXFUjbVwG1g .I16FnPGO_Sfaa7Wa_mfk .lWz4h2YXAeEZNKduXIrL{width:100px;height:100px}.HxAOgaXs9RXFUjbVwG1g .I16FnPGO_Sfaa7Wa_mfk .lWz4h2YXAeEZNKduXIrL img{width:100%;height:100%;object-fit:contain}.HxAOgaXs9RXFUjbVwG1g .I16FnPGO_Sfaa7Wa_mfk .GLtUPmMliHsJVFIzVxMx{display:flex;flex-flow:column nowrap}.HxAOgaXs9RXFUjbVwG1g .I16FnPGO_Sfaa7Wa_mfk .bebZGSmuAozd2f8S8FaR{grid-area:2/1/3/3}`, "",{"version":3,"sources":["webpack://./src/features/server/views/ServerDashboardView/view.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CAGF,sBACE,YAAA,CACA,UAAA,CACA,gBAAA,CAEA,kBAAA,CACA,4BAAA,CACA,QAAA,CAEA,4CACE,YAAA,CACA,UAAA,CACA,eAAA,CAEA,6BAAA,CACA,0BAAA,CACA,QAAA,CAEA,kEACE,WAAA,CACA,YAAA,CAEA,sEACE,UAAA,CACA,WAAA,CACA,kBAAA,CAIJ,kEACE,YAAA,CACA,uBAAA,CAGF,kEACE,iBAAA","sourcesContent":[".serverStatus {\n  white-space: pre-wrap;\n}\n\n.modList {\n  display: flex;\n  width: 100%;\n  max-width: 1080px;\n\n  flex-flow: row wrap;\n  justify-content: space-evenly;\n  gap: 30px;\n\n  .mod {\n    display: grid;\n    width: 100%;\n    max-width: 300px;\n\n    grid-template-columns: 1fr 2fr;\n    grid-auto-rows: min-content;\n    gap: 15px;\n\n    .imgWrapper {\n      width: 100px;\n      height: 100px;\n\n      img {\n        width: 100%;\n        height: 100%;\n        object-fit: contain;\n      }\n    }\n\n    .info {\n      display: flex;\n      flex-flow: column nowrap;\n    }\n\n    .download {\n      grid-area: 2 / 1 / 3 / 3;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serverStatus": `CqrqOsr0e73kQjOUFQnz`,
	"modList": `HxAOgaXs9RXFUjbVwG1g`,
	"mod": `I16FnPGO_Sfaa7Wa_mfk`,
	"imgWrapper": `lWz4h2YXAeEZNKduXIrL`,
	"info": `GLtUPmMliHsJVFIzVxMx`,
	"download": `bebZGSmuAozd2f8S8FaR`
};
export default ___CSS_LOADER_EXPORT___;
