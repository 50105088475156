import React from 'react';

import { BackgroundImages } from '@/assets/backgrounds/index.ts';
import { ImageCarousel } from '@/common/components/index.ts';

import styles from './layout.module.scss';

const BaseLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <main className={styles.main}>
      <ImageCarousel images={BackgroundImages} randomize />
      {children}
    </main>
  );
};

export { BaseLayout };
