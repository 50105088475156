import { useEffect, useMemo, useState } from 'react';

import styles from './component.module.scss';

const ImageCarousel = ({
  images,
  randomize
}: {
  images: string[];
  randomize?: boolean;
}) => {
  const [imageIndex, setImageIndex] = useState(0);
  const imagesMemo = useMemo(() => {
    const memo = [...images];
    if (randomize) {
      memo.sort(() => Math.random() - 1);
    }
    return memo;
  }, [images, randomize]);
  const carouselDirection = useMemo(() => ({ value: 1 }), []);

  useEffect(() => {
    const iid = setInterval(() => {
      setImageIndex((imageIndex) => {
        if (imageIndex === 0) {
          carouselDirection.value = 1;
        }

        if (imageIndex === imagesMemo.length - 1) {
          carouselDirection.value = -1;
        }

        return imageIndex + carouselDirection.value;
      });
    }, 30000);

    return () => {
      clearInterval(iid);
    };
  }, [imagesMemo, carouselDirection]);

  return (
    <div className={styles.main}>
      <div className={styles.slider} style={{ left: `${imageIndex * -100}%` }}>
        {imagesMemo.map((img, index) => (
          <img
            key={index}
            className={styles.image}
            src={img}
            alt={`Carousel Image ${index}`}
          />
        ))}
      </div>
    </div>
  );
};

export { ImageCarousel };
