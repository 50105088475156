import Variant1 from './variant1.png';
import Variant2 from './variant2.png';
import Variant3 from './variant3.png';
import Variant4 from './variant4.png';
import Variant5 from './variant5.png';
import Variant6 from './variant6.png';
import Variant7 from './variant7.png';
import Variant8 from './variant8.png';
import Variant9 from './variant9.png';
import Variant10 from './variant10.png';
import Variant11 from './variant11.png';
import Variant12 from './variant12.png';
import Variant13 from './variant13.png';
import Variant14 from './variant14.png';
import Variant15 from './variant15.png';
import Variant16 from './variant16.png';
import Variant17 from './variant17.png';
import Variant18 from './variant18.png';
import Variant19 from './variant19.png';
import Variant20 from './variant20.png';
import Variant21 from './variant21.png';

const BackgroundImages = [
  Variant1,
  Variant2,
  Variant3,
  Variant4,
  Variant5,
  Variant6,
  Variant7,
  Variant8,
  Variant9,
  Variant10,
  Variant11,
  Variant12,
  Variant13,
  Variant14,
  Variant15,
  Variant16,
  Variant17,
  Variant18,
  Variant19,
  Variant20,
  Variant21
];

export { BackgroundImages };
