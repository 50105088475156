import dayjs from 'dayjs';
import jwtDecode, { JwtPayload } from 'jwt-decode';

const getJWTTokenExp = (token: IJWTToken) => {
  const payload = (jwtDecode as unknown as (...args: any[]) => JwtPayload)(
    token
  );

  if (typeof payload.exp !== 'number') {
    throw new Error('Invalid token exp field');
  }

  return dayjs.unix(payload.exp);
};

const isJWTTokenExpValid = (token: IJWTToken) => {
  return dayjs().isBefore(getJWTTokenExp(token));
};

export { getJWTTokenExp, isJWTTokenExpValid };
