import React, { useEffect } from 'react';

import { useSignInRedirect } from '../../hooks/index.ts';
import { useAuthProvider } from '../../providers/index.ts';

const Authenticated = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuthProvider();
  const redirectSignIn = useSignInRedirect();

  useEffect(() => {
    if (!auth.cognitoUser) {
      redirectSignIn();
    }
  }, [auth.cognitoUser]);

  if (!auth.cognitoUser) {
    return null;
  } else {
    return <>{children}</>;
  }

  return <>{children}</>;
};

export { Authenticated };
