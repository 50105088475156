// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js!../../../public/fonts/minecraft.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../../../node_modules/css-loader/dist/cjs.js!../../../public/fonts/mojangles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{box-sizing:border-box;padding:0;margin:0}html,body{overflow-x:hidden;font-family:mojangles,monospace;text-shadow:2px 2px 0px rgba(0,0,0,.5);font-size:14px;letter-spacing:.5px;color:#fff}a{color:inherit;text-decoration:none}`, "",{"version":3,"sources":["webpack://./src/common/styles/global.scss","webpack://./src/common/styles/font.scss"],"names":[],"mappings":"AAKA,EACE,qBAAA,CACA,SAAA,CACA,QAAA,CAGF,UAEE,iBAAA,CCZA,+BAAA,CACA,sCAAA,CACA,cAAA,CACA,mBAAA,CACA,UAAA,CDaF,EACE,aAAA,CACA,oBAAA","sourcesContent":["@use 'common/styles/font.scss' as *;\n\n@import url('@public/fonts/minecraft.css');\n@import url('@public/fonts/mojangles.css');\n\n* {\n  box-sizing: border-box;\n  padding: 0;\n  margin: 0;\n}\n\nhtml,\nbody {\n  overflow-x: hidden;\n\n  @include base-font;\n}\n\na {\n  color: inherit;\n  text-decoration: none;\n}\n","@mixin base-font {\n  font-family: mojangles, monospace;\n  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);\n  font-size: 14px;\n  letter-spacing: 0.5px;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
