import React, { useEffect, useMemo, useState } from 'react';

import { DeploymentRegions } from '@veriok/infra';
import { CognitoPool, CognitoPoolUser } from '@veriok/cognito';
import { EnvironmentContext } from '@veriok/lib';

import { IAuthProviderContext } from './types.ts';

const AuthProviderContext = React.createContext<IAuthProviderContext | null>(
  null
);

const AuthProvider = ({
  children,
  userPoolId,
  userPoolClientId
}: {
  userPoolId: string;
  userPoolClientId: string;
  children: React.ReactNode;
}) => {
  const cognitoPool = useMemo(
    () =>
      new CognitoPool(
        'veriok-auth',
        DeploymentRegions[EnvironmentContext.stage],
        userPoolId,
        userPoolClientId
      ),
    []
  );
  const [cognitoUser, setCognitoUser] = useState<CognitoPoolUser | null>(null);

  useEffect(() => {
    if (!cognitoUser) {
      setCognitoUser(cognitoPool.getStoreUser());
    }
  }, []);

  const providerContext: IAuthProviderContext = {
    cognitoPool,
    cognitoUser,
    getUser: (username) => {
      const user = cognitoPool.getUser(username);
      user.on('authenticated', () => {
        setCognitoUser(user);
        cognitoPool.setStoreUser(user);
      });
      return user;
    },
    logoutUser: () => {
      cognitoPool.deleteStoreUser();
      setCognitoUser(null);
    }
  };

  return (
    <AuthProviderContext.Provider value={providerContext}>
      {children}
    </AuthProviderContext.Provider>
  );
};

export { AuthProvider, AuthProviderContext };
