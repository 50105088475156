import { useEffect } from 'react';

import { Authenticated, Button, Link } from '@/common/components/index.ts';
import { FetchStatus } from '@/common/consts/index.ts';
import {
  useFetchableOperation,
  useFetchableResource
} from '@/common/hooks/index.ts';
import { useApiProvider } from '@/common/providers/index.ts';
import { IModMeta, IServerStatus } from '@veriok/lib';

import styles from './view.module.scss';

const ServerDashboardView = () => {
  const apiProvider = useApiProvider();
  const serverStatus = useFetchableResource<{ data: IServerStatus } | null>(
    null
  );
  const serverMods = useFetchableResource<{ data: IModMeta[] } | null>(null);
  const serverFetchableOperation = useFetchableOperation();

  const requestServerStatus = serverStatus.wrappedFetch(async () => {
    const response = await apiProvider.apiAuthenticated.get('/server/status');
    return response.data;
  });

  const requestServerMods = serverMods.wrappedFetch(async () => {
    const response = await apiProvider.apiAuthenticated.get('/mods');
    return response.data;
  });

  const doServerStart = serverFetchableOperation.wrappedFetch(async () => {
    await apiProvider.apiAuthenticated.post('/server/start');
    requestServerStatus();
  });

  const doServerStop = serverFetchableOperation.wrappedFetch(async () => {
    await apiProvider.apiAuthenticated.post('/server/stop');
    requestServerStatus();
  });

  useEffect(() => {
    requestServerStatus();
    requestServerMods();
  }, []);

  if (serverStatus.resource?.data.game?.faviconDataUrl) {
    serverStatus.resource.data.game.faviconDataUrl = '';
  }

  return (
    <Authenticated>
      <span className={styles.serverStatus}>
        {serverStatus.resource !== null &&
          JSON.stringify(serverStatus.resource, null, 2)}
      </span>
      <Button
        onClick={doServerStart}
        loading={serverFetchableOperation.status === FetchStatus.PENDING}
        disabled={serverFetchableOperation.status === FetchStatus.PENDING}
      >
        Start Server
      </Button>
      <Button
        onClick={doServerStop}
        loading={serverFetchableOperation.status === FetchStatus.PENDING}
        disabled={serverFetchableOperation.status === FetchStatus.PENDING}
      >
        Stop Server
      </Button>

      {serverMods.resource !== null && (
        <div className={styles.modList}>
          {serverMods.resource.data.map((modMeta) => (
            <div className={styles.mod}>
              <div className={styles.imgWrapper}>
                <img src={modMeta.logoUrl} />
              </div>
              <div className={styles.info}>
                <span className={styles.name}>{modMeta.name.trim()}</span>
                <span className={styles.description}>
                  {modMeta.description.trim()}
                </span>
                <span className={styles.version}>
                  Version - {modMeta.version?.trim()}
                </span>
              </div>
              <Link
                className={styles.download}
                href={modMeta.modUrl}
                target="_blank"
              >
                Download
              </Link>
            </div>
          ))}
        </div>
      )}
    </Authenticated>
  );
};

export { ServerDashboardView };
