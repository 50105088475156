// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vR0enaaXubU7EU_dK2bi,.YW58Dp2FZZe82QsZils8{display:flex;flex-flow:column nowrap;align-items:center}.vR0enaaXubU7EU_dK2bi{width:100%;height:100%}.YW58Dp2FZZe82QsZils8{width:100%;max-width:1200px;padding:10px;row-gap:10px}`, "",{"version":3,"sources":["webpack://./src/common/layouts/BaseLayout/layout.module.scss"],"names":[],"mappings":"AAAA,4CAEE,YAAA,CACA,uBAAA,CACA,kBAAA,CAGF,sBACE,UAAA,CACA,WAAA,CAGF,sBACE,UAAA,CACA,gBAAA,CAEA,YAAA,CACA,YAAA","sourcesContent":[".main,\n.content {\n  display: flex;\n  flex-flow: column nowrap;\n  align-items: center;\n}\n\n.main {\n  width: 100%;\n  height: 100%;\n}\n\n.content {\n  width: 100%;\n  max-width: 1200px;\n\n  padding: 10px;\n  row-gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `vR0enaaXubU7EU_dK2bi`,
	"content": `YW58Dp2FZZe82QsZils8`
};
export default ___CSS_LOADER_EXPORT___;
