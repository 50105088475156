// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ESzryYMIJsxEsTiDX1QX{display:flex;justify-content:center;align-items:center;flex-flow:column nowrap;row-gap:10px;border:1px solid #e0e0e0;border-radius:8px;padding:10px;background-color:#fff}`, "",{"version":3,"sources":["webpack://./src/common/components/Card/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAEA,sBAAA,CACA,kBAAA,CACA,uBAAA,CACA,YAAA,CAEA,wBAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA","sourcesContent":[".main {\n  display: flex;\n\n  justify-content: center;\n  align-items: center;\n  flex-flow: column nowrap;\n  row-gap: 10px;\n\n  border: 1px solid #e0e0e0;\n  border-radius: 8px;\n  padding: 10px;\n  background-color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `ESzryYMIJsxEsTiDX1QX`
};
export default ___CSS_LOADER_EXPORT___;
