import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import LogoIconImage from '@/assets/logos/logo-500px.png';
import { Input, Button } from '@/common/components/index.ts';
import { useAuthProvider } from '@/common/providers/index.ts';
import { useSignInRedirect } from '@/common/hooks/index.ts';
import {
  ChallengeNameType,
  CognitoPoolUser,
  ICognitoAuthChallenge
} from '@veriok/cognito';

import styles from './view.module.scss';

const SignInView = () => {
  const auth = useAuthProvider();
  const consumeSignInRedirect = useSignInRedirect.useConsumeRedirect();

  const [error, setError] = useState('');
  const [currentUser, setCurrentUser] = useState<CognitoPoolUser | null>(null);
  const [currentChallenge, setCurrentChallenge] =
    useState<ICognitoAuthChallenge<any> | null>(null);

  const signInForm = useForm<{ username: string; password: string }>({});
  const signInFormSubmit = signInForm.handleSubmit(async (data) => {
    const user = auth.getUser(data.username);

    try {
      const authenticated = await user
        .initiateAuthFlow()
        .userPasswordAuth(data.password);

      if (authenticated) {
        consumeSignInRedirect('/');
      } else {
        setCurrentChallenge(user.authFlow.lastChallenge);
      }
      setCurrentUser(user);
    } catch (e) {
      console.dir(e);
      if (
        String(e).match(
          /NotAuthorizedException: Incorrect username or password/i
        )
      ) {
        setError('Incorrect username or password');
      } else {
        setError(String(e));
      }
    }
  });

  const newPasswordForm = useForm<{ passwordA: string; passwordB: string }>({});
  const newPasswordFormSubmit = newPasswordForm.handleSubmit(async (data) => {
    if (!currentUser) {
      throw new Error('Inconsistency Error => No current user for flow');
    }

    const user = currentUser;

    try {
      const authenticated = await user
        .respondAuthFlow()
        .newPasswordRequired(data.passwordA);

      if (authenticated) {
        consumeSignInRedirect('/');
      } else {
        setCurrentChallenge(user.authFlow.lastChallenge);
      }
    } catch (e) {
      console.dir(e);
      if (
        String(e).match(
          /NotAuthorizedException: Incorrect username or password/i
        )
      ) {
        setError('Incorrect username or password');
      } else {
        setError(String(e));
      }
    }
  });

  useEffect(() => {
    if (auth.cognitoUser) {
      consumeSignInRedirect('/');
    }
  }, [auth.cognitoUser]);

  return (
    <div className={styles.main}>
      {currentUser === null && (
        <form className={styles.container} onSubmit={signInFormSubmit}>
          <img src={LogoIconImage} alt="Veriok Logo" />
          <h2>Veriok Server Control</h2>
          <Input
            placeholder="username"
            error={signInForm.formState.errors.username?.message}
            {...signInForm.register('username', {
              required: 'username is required'
            })}
          />
          <Input
            placeholder="password"
            type="password"
            error={signInForm.formState.errors.password?.message}
            {...signInForm.register('password', {
              required: 'password is required'
            })}
          />
          {error && <span className={styles.error}>{error}</span>}
          <Button
            type="submit"
            loading={signInForm.formState.isSubmitting}
            disabled={signInForm.formState.isSubmitting}
          >
            Sign In
          </Button>
        </form>
      )}
      {currentUser !== null &&
        currentChallenge !== null &&
        currentChallenge.name === ChallengeNameType.NEW_PASSWORD_REQUIRED && (
          <form className={styles.container} onSubmit={newPasswordFormSubmit}>
            <img src={LogoIconImage} alt="Veriok Logo" />
            <h2>Veriok Server Control</h2>
            <h4>Please change your password</h4>
            <Input
              placeholder="new password"
              type="password"
              error={newPasswordForm.formState.errors.passwordA?.message}
              {...newPasswordForm.register('passwordA', {
                required: 'new password is required'
              })}
            />
            <Input
              placeholder="repeat password"
              type="password"
              error={newPasswordForm.formState.errors.passwordB?.message}
              {...newPasswordForm.register('passwordB', {
                required: 'password confirmation is required',
                validate: {
                  passwordMatch: (value) =>
                    newPasswordForm.getValues().passwordA === value ||
                    'passwords no not match'
                }
              })}
            />
            {error && <span className={styles.error}>{error}</span>}
            <Button
              type="submit"
              loading={newPasswordForm.formState.isSubmitting}
              disabled={newPasswordForm.formState.isSubmitting}
            >
              Change Password
            </Button>
          </form>
        )}
    </div>
  );
};

export { SignInView };
