enum DeploymentStage {
  PRODUCTION = 'production',
  STAGING = 'staging',
  ALPHA = 'alpha'
}

const DeploymentProfile = 'juandavidkincaid';
const DeploymentFQDN = 'juandavidkincaid.me';

const DeploymentRegions: Record<DeploymentStage, string> = {
  [DeploymentStage.PRODUCTION]: 'us-east-1',
  [DeploymentStage.STAGING]: 'us-east-2',
  [DeploymentStage.ALPHA]: 'us-west-1'
};

const DeploymentDomains: Record<DeploymentStage, string> = {
  [DeploymentStage.PRODUCTION]: 'veriok.juandavidkincaid.me',
  [DeploymentStage.STAGING]: 'veriok-staging.juandavidkincaid.me',
  [DeploymentStage.ALPHA]: 'veriok-alpha.juandavidkincaid.me'
};

const ApiDeploymentDomains: Record<DeploymentStage, string> = {
  [DeploymentStage.PRODUCTION]: 'api.veriok.juandavidkincaid.me',
  [DeploymentStage.STAGING]: 'api.veriok-staging.juandavidkincaid.me',
  [DeploymentStage.ALPHA]: 'api.veriok-alpha.juandavidkincaid.me'
};

const ModContentDeploymentDomains: Record<DeploymentStage, string> = {
  [DeploymentStage.PRODUCTION]: 'mods.veriok.juandavidkincaid.me',
  [DeploymentStage.STAGING]: 'mods.veriok-staging.juandavidkincaid.me',
  [DeploymentStage.ALPHA]: 'mods.veriok-alpha.juandavidkincaid.me'
};

const isDeploymentStage = (value: unknown): value is DeploymentStage =>
  typeof value === 'string' &&
  Object.values(DeploymentStage).includes(value as any);

export {
  DeploymentStage,
  DeploymentProfile,
  DeploymentFQDN,
  DeploymentRegions,
  DeploymentDomains,
  ApiDeploymentDomains,
  ModContentDeploymentDomains,
  isDeploymentStage
};
